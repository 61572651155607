<template>
  <!-- 用户申请页面 -->
  <v-container fluid>
    <v-data-table :search="searchByUserApply" :headers="listHeaders" :items="userApplyList">
      <template v-slot:top>
        <v-row dense>
          <v-col cols="12" sm="4" offset-sm="8">
            <v-text-field
              v-model="searchByUserApply"
              :label="$t('lang.station.searchLabel')"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.type="{item}">
        <span v-if="item.type == 'STUDENT'">{{ $t('lang.user.studentCertification')}}</span>
        <span v-else>{{ $t('lang.user.companyCertification')}}</span>
      </template>
      <template v-slot:item.status="{item}">
        <span v-if="item.status == 'PASS'" class="success--text">{{$t('lang.user.verified')}}</span>
        <span
          v-else-if="item.status == 'REFUSE'"
          class="error--text"
        >{{$t('lang.user.refuseCertified')}}</span>
        <span v-else-if="item.status == 'CHECKING'">{{$t('lang.user.checkingCertified')}}</span>
      </template>
      <template v-slot:item.actions="{item}">
        <v-btn
          color="primary lighten-1"
          small
          class="mr-2"
          @click="showUserApplyDialog=true;editUserApply(item)"
        >{{$t('lang.station.editBtn')}}</v-btn>
      </template>
    </v-data-table>
    <!-- 用户申请对话框 -->
    <v-dialog max-width="500" v-model="showUserApplyDialog">
      <v-card>
        <v-card-title>{{$t('lang.user.userApplication')}}</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                disabled
                v-model="userApplyInDialog.username"
                :label="$t('lang.register.usernameLabel')"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row dense>
            <v-col cols="12">
              <v-text-field
                disabled
                v-model="userApplyInDialog.type"
                :label="$t('lang.user.apply.certStatus')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                disabled
                v-model="userApplyInDialog.status"
                :label="$t('lang.setting.certificationStatus')"
              ></v-text-field>
            </v-col>
          </v-row> -->
          <v-row dense>
            <v-col cols="12">
              <v-sheet
                min-height="120"
                max-width="200"
                class="grey lighten-4 d-flex align-center justify-center"
              >
                <v-img
                  :src="userApplyInDialog.base64Photo"
                  max-height="85"
                  max-width="85"
                  style="position:absolute;"
                  @click="showBigImgDialog=true;bigImgUrl=userApplyInDialog.base64Photo"
                ></v-img>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- 操作按钮 -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showUserApplyDialog=false">{{$t('lang.station.cancelBtn')}}</v-btn>
          <v-btn
            class="error"
            v-if="userApplyInDialog.status=='CHECKING'"
            @click="showUserApplyDialog=false;approvalUserApply(userApplyInDialog.id, 'REFUSE')"
          >{{$t('lang.user.apply.rejectBtn')}}</v-btn>
          <v-btn
            class="primary"
            v-if="userApplyInDialog.status=='CHECKING'"
            @click="showUserApplyDialog=false;approvalUserApply(userApplyInDialog.id, 'PASS')"
          >{{$t('lang.user.apply.agreeBtn')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <v-dialog v-model="showBigImgDialog" max-width="500">
      <v-img :src="bigImgUrl"></v-img>
    </v-dialog>
    <v-snackbar
      :timeout="tips.timeout"
      v-model="tips.showSnackbar"
      :color="tips.snackbarColor"
      :top="true"
    >{{ tips.snackbarText }}</v-snackbar>
  </v-container>
</template>
<script>
import axios from "axios";
import qs from "qs";
import commonCfg from "../../config/common";
let apiUrl = commonCfg.commonUrl;
let vm;
export default {
  data() {
    return {
      userApplyList: [],
      listHeaders: [
        { value: "username", text: this.$t("lang.register.usernameLabel") },
        {
          value: "type",
          text: this.$t("lang.user.apply.certStatus"),
        },
        { value: "status", text: this.$t("lang.setting.certificationStatus") },
        {
          value: "actions",
          text: this.$t("lang.station.operation"),
          sortable: false,
        },
      ],
      searchByUserApply: "",
      showUserApplyDialog: false,
      userApplyInDialog: {
        username: "",
        type: "",
        status: "",
        base64Photo: "",
        id: -1
      },
      tips: {
        showSnackbar: false,
        snackbarColor: "error",
        snackbarText: "",
        timeout: -1,
      },
      showBigImgDialog: false,
      bigImgUrl: ''
    };
  },
  created() {
    vm = this;
    this.getUserApplyList();
  },
  methods: {
      //通知提示工具
    showTips(text, color, timeout) {
      this.tips.showSnackbar = true;
      this.tips.snackbarText = text;
      this.tips.snackbarColor = color;
      if (timeout) {
        this.tips.timeout = timeout;
      } else {
        this.tips.timeout = 3000;
      }
    },
    //获得用户申请列表
    getUserApplyList() {
      axios
        .get(`${apiUrl}admin/user/apply/list`)
        .then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            vm.userApplyList = res.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editUserApply(item) {
      this.userApplyInDialog = {
        username: item.username,
        type: item.type,
        status: item.status,
        base64Photo: item.attributes.base64Photo,
        id: item.userId
      };
    },
    //审批用户申请
    approvalUserApply(userId, status) {
      axios
        .post(
          `${apiUrl}admin/user/apply/update`,
          qs.stringify({
            userId: userId,
            status: status,
          })
        )
        .then((res) => {
          if (res.status == 200) {
            this.showTips(vm.$t("lang.tips.operationSuccess"), "success");
            vm.getUserApplyList();
            return;
          }
          this.showTips(vm.$t("lang.tips.operationFailed"), "error");
        })
        .catch((error) => {
          console.log(error);
          this.showTips(vm.$t("lang.tips.operationFailed"), "error");
        });
    },
  },
};
</script>